import { useConvertNumberToMoneyWithoutSymbol } from '@expane/logic/currency'
import { checkUnitCanBeFloat, getUnitsName } from '@expane/logic/product'
import { DeleteButtonInTable, Input, Table } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'
import { observer } from 'mobx-react-lite'
import { FC, useMemo } from 'react'
import { Controller, useFieldArray, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { PostingMovementProps } from 'widgets/MovementCreateDialogs/PostingMovementDialog'
import { ProductDto } from '../ChooseMovementProductsDialog'
import { LeftoverCell } from '../LeftoverCell'

export const PostingMovementProductsList: FC<PostingMovementProps> = observer(({ control }) => {
  const branchId = store.branch.branchId

  const { t } = useTranslation()
  const convertNumberToMoney = useConvertNumberToMoneyWithoutSymbol(branchId)

  const { fields, remove } = useFieldArray({ control, name: 'products', keyName: 'generatedId' })
  const isThereOneField = fields.length === 1

  const columns = useMemo<ColumnDef<ProductDto>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('product.name'),
        size: 300,
      },
      {
        id: 'quantity',
        header: t('qty'),
        size: 100,
        cell: data => {
          const index = data.row.index
          const product = data.row.original
          return (
            <Controller
              control={control}
              name={`products.${index}` as 'products.0'}
              rules={{
                validate: {
                  biggerThanZero: value => value.quantity > 0,
                  integer: value => {
                    if (checkUnitCanBeFloat(value.unit)) return true

                    return Number.isInteger(value.quantity)
                  },
                },
              }}
              render={({ field: { onChange, value }, formState: { errors } }) => (
                <Input
                  containerClassName="w-20"
                  type="number"
                  min={0}
                  height="small"
                  onChange={e => {
                    onChange({ ...product, quantity: Number(e.target.value) })
                  }}
                  value={value.quantity.toString()}
                  errorMessage={{
                    isShown: Boolean(errors.products?.[index]),
                    text: ' ',
                    reserveIndent: false,
                  }}
                />
              )}
            />
          )
        },
      },
      {
        id: 'inStock',
        header: () => <span className="w-full text-right">{t('inStock')}</span>,
        cell: data => <WriteOffMovementLeftover product={data.row.original} control={control} />,
        size: 100,
      },
      {
        accessorKey: 'unit',
        header: t('unit.shortName'),
        cell: data => getUnitsName(data.getValue<number>(), t),
        size: 60,
      },
      {
        accessorKey: 'costPrice',
        header: () => <span className="w-full text-right">{t('price')}</span>,
        cell: data => (
          <div className="text-right">{convertNumberToMoney(data.getValue<number>())}</div>
        ),
        size: 120,
      },
      {
        id: 'removeButton',
        cell: data => {
          return (
            <DeleteButtonInTable
              onClick={() => remove(data.row.index)}
              disabled={isThereOneField}
            />
          )
        },
        size: 20,
        enableResizing: false,
      },
    ],
    [control, convertNumberToMoney, isThereOneField, remove, t],
  )

  return <Table columns={columns} data={fields} containerClassName="max-h-64" />
})

const WriteOffMovementLeftover: FC<PostingMovementProps & { product: ProductDto }> = ({
  product,
  control,
}) => {
  const fromStorageId = useWatch({ control, name: 'toStorageId' })

  return <LeftoverCell product={product} storageId={fromStorageId} />
}
