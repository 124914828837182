import {
  addDays,
  addMinutes,
  differenceInCalendarMonths,
  eachDayOfInterval,
  endOfDay,
  format,
  getDay,
  getHours,
  getMinutes,
  isAfter,
  isBefore,
  isEqual,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  isSameDay,
  set,
  setDate,
  setHours,
  setMinutes,
  setMonth,
  setYear,
  startOfDay,
} from 'date-fns'
import {
  format as formatTZ,
  fromZonedTime as fromZonedTimeTZ,
  ToDateOptionsWithTZ,
  toZonedTime as toZonedTimeTZ,
} from 'date-fns-tz'
import { useEffect, useState } from 'react'

export const getTimezoneWithBackwardCompatibility = (timeZone: string | undefined): string => {
  if (timeZone === 'Europe/Kyiv')
    try {
      new Intl.DateTimeFormat('uk-UA', { timeZone: 'Europe/Kyiv' })
      return 'Europe/Kyiv'
    } catch {
      return 'Europe/Kiev'
    }

  return timeZone || DEFAULT_TIMEZONE
}

export const DEFAULT_TIMEZONE = getTimezoneWithBackwardCompatibility('Europe/Kyiv')

export const MINUTE_IN_MS = 60 * 1000
export const HOUR_IN_MS = 60 * MINUTE_IN_MS

const HOURS_MINUTES_FORMAT = 'HH:mm'
export const formatTimeFromDate = (date: Date) => format(date, HOURS_MINUTES_FORMAT)

const FULL_DATE_FORMAT = 'dd.MM.yyyy'
export const formatFullDate = (date: Date) => format(date, FULL_DATE_FORMAT)

export const toZonedTime = (
  date: Date | string | number,
  timeZone: string,
  options?: ToDateOptionsWithTZ,
): Date => toZonedTimeTZ(date, getTimezoneWithBackwardCompatibility(timeZone), options)

export const fromZonedTime = (
  date: Date | string | number,
  timeZone: string,
  options?: ToDateOptionsWithTZ,
): Date => fromZonedTimeTZ(date, getTimezoneWithBackwardCompatibility(timeZone), options)

// Этот формат нужен для 'react-native-calendars'
const FULL_DATE_FORMAT_WITH_DASH = 'yyyy-MM-dd'
export const formatFullDateWithDash = (date: Date) => format(date, FULL_DATE_FORMAT_WITH_DASH)

const fullDateTimeFormat = `${FULL_DATE_FORMAT} ${HOURS_MINUTES_FORMAT}`
export const formatFullDateTime = (date: Date) => format(date, fullDateTimeFormat)

const DAY_MONTH_FORMAT = 'dd.MM'
export const formatDayMonth = (date: Date) => format(date, DAY_MONTH_FORMAT)

const DAY_FORMAT = 'dd'
export const formatDayFromDate = (date: Date) => format(date, DAY_FORMAT)

export const getOverLappingInterval = (
  salaryPeriod: { start: Date; end: Date },
  datePeriod: { start: Date; end: Date },
): Array<Date> => {
  const salaryPeriodEachDay = eachDayOfInterval({
    start: salaryPeriod.start,
    end: salaryPeriod.end,
  })

  const datePeriodEachDay = eachDayOfInterval({
    start: datePeriod.start,
    end: datePeriod.end,
  })

  return salaryPeriodEachDay.filter(sDay => datePeriodEachDay.some(dDay => isSameDay(sDay, dDay)))
}

export const getMinutesFromFloat = (time: number) => {
  return Number.isInteger(time) ? 0 : 30
}

export const setTimeForDateByFloat = (value: number, date: Date) => {
  // целая часть - часы
  // дробная часть - минуты
  return set(date, {
    hours: value,
    minutes: getMinutesFromFloat(value),
    seconds: 0,
    milliseconds: 0,
  })
}

export const getTimeFromDate = (date: Date) => {
  const startHour = getHours(date)
  const startMinute = getMinutes(date) / 60
  return startHour + startMinute
}

export const getWeekDayNumber = (date: Date) => {
  const day = getDay(date)
  // this function return 0 if it is sunday
  if (day === 0) return 6
  return day - 1
}

export const isDateSameDayOrAfter = (date: Date, dateToCompare: Date) =>
  isAfter(date, dateToCompare) || isSameDay(date, dateToCompare)

export const isDateSameDayOrBefore = (date: Date, dateToCompare: Date) =>
  isBefore(date, dateToCompare) || isSameDay(date, dateToCompare)

// ignore time, only date
export const isDateBefore = (date: Date, dateToCompare: Date) =>
  isBefore(date, dateToCompare) && !isSameDay(date, dateToCompare)

// ignore time, only date
export const isDateAfter = (date: Date, dateToCompare: Date) =>
  isAfter(date, dateToCompare) && !isSameDay(date, dateToCompare)

export const isDateAfterCurrentDate = (date: Date, timezone: string) =>
  isAfter(date, createCurrentDate(timezone))

export const isDateStartOfDay = (date: Date) => isEqual(date, startOfDay(date))
export const isDateEndOfDay = (date: Date) => isEqual(date, endOfDay(date))

export const getRoundedDate = (minutes: number, date: Date) => {
  const coefficient = MINUTE_IN_MS * minutes // convert minutes to ms
  return new Date(Math.round(date.getTime() / coefficient) * coefficient)
}

export const parseNewDateFromText = (text: string, date: Date | undefined, timezone: string) => {
  // разделяем введённые данные на число, месяц и год
  const parts = text.split(/\.|\/|-/)

  let newDate = date ?? createCurrentDate(timezone)

  // проверяем указана ли дата и является ли она числом
  if (parts[0] && !Number.isNaN(Number(parts[0]))) newDate = setDate(newDate, Number(parts[0]))
  // проверяем указан ли месяц и является ли он числом
  if (parts[1] && !Number.isNaN(Number(parts[1]))) newDate = setMonth(newDate, Number(parts[1]) - 1)
  // проверяем указан ли год и является ли он числом
  if (parts[2] && !Number.isNaN(Number(parts[2]))) newDate = setYear(newDate, Number(parts[2]))

  return newDate
}

export const parseNewTimeFromText = (text: string, date: Date | undefined, timezone: string) => {
  // разделяем введённые данные на часы и минуты
  const parts = text.split(':')

  let newDate = date ?? createCurrentDate(timezone)

  // проверяем указаны ли часы и являются ли они числом
  if (parts[0] && !Number.isNaN(Number(parts[0]))) newDate = setHours(newDate, Number(parts[0]))
  // проверяем указаны ли минуты и являются ли они числом
  if (parts[1] && !Number.isNaN(Number(parts[1]))) newDate = setMinutes(newDate, Number(parts[1]))

  return newDate
}

export const isIntervalWithinLastDaysOfMonth = (startDate: Date, endDate: Date) =>
  isFirstDayOfMonth(startDate) && isLastDayOfMonth(endDate)

export const checkIfIsMoreThanOneMonthInPeriod = (fromDate: Date, toDate: Date) => {
  const monthsInPeriod = differenceInCalendarMonths(addMinutes(toDate, 1), fromDate)

  return monthsInPeriod > 1
}

export const isToday = (date: Date, timezone: string | undefined) => {
  const today = timezone ? toZonedTime(new Date(), timezone) : new Date()
  return isSameDay(date, today)
}

export const createCurrentDate = (timezone: string | undefined): Date => {
  if (!timezone) return new Date()
  return toZonedTime(new Date(), timezone)
}

/**
 * @description Use to remove *date timezone* and insert a new *timezone*
 * @description The problem is that the user's timezone may differ from the business's timezone.
 * But it means that the user is editing something in exactly the timezone in which the business is located
 */
export const replaceDateTimezone = (date: Date | string, timezone: string | undefined) => {
  const initialDate: Date = typeof date === 'string' ? new Date(date) : date

  if (!timezone) return initialDate

  const formattedDate = formatTZ(
    new Date(
      initialDate.getFullYear(),
      initialDate.getMonth(),
      initialDate.getDate(),
      initialDate.getHours(),
      initialDate.getMinutes(),
      initialDate.getSeconds(),
      initialDate.getMilliseconds(),
    ),
    'yyyy-MM-dd HH:mm:ssXXX',
    {
      timeZone: getTimezoneWithBackwardCompatibility(timezone),
    },
  )

  return new Date(formattedDate)
}

export const useStartOfCurrentDay = (timezone: string | undefined) => {
  const [startOfCurrentDay, setStartOfCurrentDay] = useState(() =>
    startOfDay(createCurrentDate(timezone)),
  )

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newDate = createCurrentDate(timezone)

      if (!isSameDay(newDate, startOfCurrentDay)) setStartOfCurrentDay(startOfDay(newDate))
    }, MINUTE_IN_MS)

    return () => {
      clearInterval(intervalId)
    }
  }, [startOfCurrentDay, timezone])

  return startOfCurrentDay
}

export const getTimeWithMinutesFromDate = (date: Date) =>
  date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })

export const generateDatesFromTodayForMonth = (timezone: string): Date[] => {
  const today = startOfDay(createCurrentDate(timezone))

  const dates: Date[] = []

  // Генерируем даты на 30 дней вперёд от текущей даты
  for (let i = 0; i < 30; i++) {
    const nextDate = addDays(today, i)
    // Преобразуем дату обратно в UTC, чтобы сохранить правильный формат
    const utcDate = fromZonedTime(nextDate, timezone)
    dates.push(utcDate)
  }

  return dates
}

export * from 'date-fns/locale'

export * from './constants'

export * from './timezone'

export {
  add,
  addDays,
  addHours,
  addMinutes,
  addMonths,
  addYears,
  areIntervalsOverlapping,
  compareAsc,
  compareDesc,
  differenceInCalendarDays,
  differenceInDays,
  differenceInHours,
  differenceInMonths,
  differenceInYears,
  eachDayOfInterval,
  eachMinuteOfInterval,
  eachMonthOfInterval,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  formatISO,
  getDate,
  getDay,
  getDaysInMonth,
  getHours,
  getMinutes,
  getMonth,
  getOverlappingDaysInIntervals,
  getYear,
  isAfter,
  isBefore,
  isDate,
  isEqual,
  isFuture,
  isSameDay,
  isSameMonth,
  isSameSecond,
  isSameWeek,
  isSameYear,
  isTomorrow,
  isWithinInterval,
  max,
  min,
  parse,
  parseISO,
  set,
  setHours,
  setMinutes,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subHours,
  subMinutes,
  subMonths,
  subYears,
  roundToNearestMinutes,
  addWeeks,
  getWeek,
} from 'date-fns'
